import {createContext} from '@deezer/react-utils';
import type {Trackables} from '@app/types/tracking';
import {usePageTracking} from '@app/modules/tracking/use-page-tracking';
import type {logCenter} from './logCenter';
import {useLogCenter} from './logCenter';
import type {Options} from 'react-tracking';
import {useTracking as defaultUseTracking} from 'react-tracking';
import {useEffect, useMemo} from 'react';
import {useRouter} from 'next/router';
import {getAttributionQueryParams} from '@app/modules/location';
import {useLegacyUser} from '@deezer/react-user';
import {useCDP} from './cdp';
import type {CDP, Loggable} from '@deezer/logcenter';
import {RECLogContext} from '@deezer/logcenter';
import {getClientSideUniqId} from '../abTest';
import type {CDPPageViewId} from './helpers/cdpPageView';

type TrackingContext = {
	cdp: CDP;
	cdpReady: boolean;
	logCenter: typeof logCenter;
	trackEvent(data: Partial<Trackables>): void;
};
const [TrackingContextProvider, useTrackingContext] =
	createContext<TrackingContext>();

type Props = {tracking: Partial<Trackables>};
export const TrackingProvider: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	tracking,
}) => {
	const {Track, ...trackingHook} = usePageTracking(tracking);
	const logCenter = useLogCenter();
	const [cdp, ready] = useCDP();

	return (
		<Track>
			<TrackingContextProvider
				value={{cdp, cdpReady: ready, logCenter, ...trackingHook}}
			>
				<CommonMountEvents cdpViewId={tracking.cdpViewId} />
				{children}
			</TrackingContextProvider>
		</Track>
	);
};

export const CommonMountEvents = ({
	cdpViewId,
}: {
	cdpViewId: CDPPageViewId | undefined;
}) => {
	useMountEvents(cdpViewId);
	return null;
};
const useMountEvents = (cdpViewId: CDPPageViewId | undefined) => {
	const log = useLog();
	const {query} = useRouter();
	const [legacyUser] = useLegacyUser();
	const {cdpReady} = useTrackingContext();
	const attributionContext = useMemo(() => {
		const attributionContext = getAttributionQueryParams(query);
		return Object.keys(attributionContext).length > 0
			? attributionContext
			: undefined;
	}, [query]);
	const dzr_uniq_id = getClientSideUniqId();

	useEffect(() => {
		if (cdpReady && legacyUser && dzr_uniq_id && cdpViewId) {
			log({
				type: 'cdp',
				eventName: 'pageView',
				customAttributes: {
					screen_view_name: 'explore',
					screen_view_id_type: 'page',
					screen_view_id: cdpViewId,
				},
			});
		}
	}, [cdpViewId, cdpReady, dzr_uniq_id, legacyUser, log]);

	// On page mount events
	useEffect(() => {
		const isDeezerOrEmptyReferrer =
			/deezer(|dev).com/.test(document.referrer) || document.referrer === '';
		const isMozilla = /Mozilla/.test(navigator.userAgent);
		const platform = new RECLogContext({
			name: 'standalone-landing',
			version: '1.0.0',
		}).platform.serialize() as Loggable;
		if (
			cdpReady &&
			legacyUser &&
			dzr_uniq_id &&
			isMozilla &&
			(attributionContext || !isDeezerOrEmptyReferrer)
		) {
			log({
				type: 'cdp',
				eventName: 'context_attributed',
				customAttributes: {
					dzr_unique_id: dzr_uniq_id,
					url: location.href,
					referrer: document.referrer,
					...attributionContext,
				},
			});
			setTimeout(
				() =>
					log({
						platform,
						version: '1.0.0',
						type: 'ads.adblock',
						ts: Math.round(Date.now() / 1000),
						adblock: (window as any)?.analytics.init === undefined,
					}),
				500,
			);
			log({
				platform,
				version: '1.0.0',
				type: 'mparticle.tracking_migration',
				ts: Math.round(Date.now() / 1000),
				event_name: 'attribution_context',
				event_type: 'user_action',
				dzr_uniq_id,
				event_attributes: {
					country: legacyUser.COUNTRY,
					user_id: legacyUser.USER?.USER_ID || 0,
					url: location.href,
					referrer: document.referrer,
					...attributionContext,
				},
			});
		}
	}, [cdpReady, log, attributionContext, dzr_uniq_id, legacyUser]);
};

export const useLog = () => {
	const {logCenter} = useTrackingContext();
	return logCenter.log;
};

export const useFlush = () => {
	const {logCenter} = useTrackingContext();
	return logCenter.flush;
};

export const useTracking = (
	trackingData?: Partial<Trackables>,
	options?: Partial<Options<Trackables>>,
) => {
	const {trackEvent} = defaultUseTracking(trackingData, options);
	const trackClick =
		(trackingData: Partial<Trackables> = {}) =>
		() => {
			return trackEvent({event: 'click', eventlabel: '', ...trackingData});
		};
	return {trackEvent, trackClick};
};
