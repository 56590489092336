import LogCenter, {
	RECSender,
	BatchStrategy,
	DirectStrategy,
	MemoryStorage,
	getBatchConfiguration,
	CDPSender,
} from '@deezer/logcenter';
import {config} from '@modules/config';
import {useEffect} from 'react';
import {useLegacyUser} from '@deezer/react-user';
import {getCDP} from './cdp';

const memoryStorage = new MemoryStorage();

const batchConfiguration = getBatchConfiguration({
	maxBatchSize: 10,
	autoSendInterval: 1 * 60 * 1000,
	ignoredLogTypes: ['cdp', 'mparticle.tracking_migration', 'ads.adblock'],
});

export const recSender = new RECSender(
	`https://${config.get('recHost')}/1.0/events/`,
	'',
	batchConfiguration,
);
const cdpSender = new CDPSender(getCDP());
const cdpConfiguration = {
	handledLogTypes: ['cdp'],
};

const directConfiguration = {
	handledLogTypes: ['mparticle.tracking_migration', 'ads.adblock'],
};

export const cdpStrategy = new DirectStrategy(
	cdpSender,
	memoryStorage,
	cdpConfiguration,
);
export const directStrategy = new DirectStrategy(
	recSender,
	memoryStorage,
	directConfiguration,
);
export const batchStrategy = new BatchStrategy(
	recSender,
	memoryStorage,
	batchConfiguration,
);
export const logCenterInstance = new LogCenter(
	[batchStrategy, cdpStrategy, directStrategy],
	memoryStorage,
);

export const logCenter = {
	log: logCenterInstance.log.bind(logCenterInstance),
	flush: batchStrategy.flush.bind(batchStrategy),
};

/**
 * @deprecated used internally - prefer using the one from @app/modules/tracking instead
 */
export const useLogCenter = () => {
	const [legacyUser] = useLegacyUser();
	useEffect(() => {
		recSender.setAuthToken(legacyUser?.USER_TOKEN ?? '');
	}, [legacyUser]);
	return logCenter;
};
