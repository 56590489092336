import {useLegacyUser} from '@deezer/react-user';
import type {Trackables} from '@app/types/tracking';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import type {TrackingHook} from 'react-tracking';
import {useTracking} from 'react-tracking';
import {getClientSideUniqId} from '../abTest';

function removePropertyFromObject(obj: any, property: string) {
	const {[property]: _, ...rest} = obj;
	return rest;
}

/**
 * Helper hook to ease pageview tracking and inject common data into the data layer
 * @deprecated - do not use this hook directly, it's used to better separate code in the tracking provider
 */
export const usePageTracking = (
	options: Partial<Trackables> = {},
): TrackingHook<Trackables> => {
	const {locale} = useRouter();
	const cleanedOptions = removePropertyFromObject(options, 'cdpViewId');
	const {pagename, ...rest} = cleanedOptions;
	const trackingHook = useTracking({
		pagecategory: 'explore',
		pagename,
		language: locale,

		/**
		 * This allowes to forward a default eventcategory for components shared between
		 * multiple pages. Most categories are the pagename anyway and it does not change
		 * anything if you want to specify another one then you can!
		 */
		eventcategory: pagename,
		...rest,
	});
	const [legacyUser] = useLegacyUser();

	// Injects page / user data into the datalayer
	useEffect(() => {
		if (legacyUser) {
			trackingHook.trackEvent({
				event: 'pageView',
				dzr_uniq_id: getClientSideUniqId(),
				deezer_user_id: String(legacyUser.USER?.USER_ID),
				country: legacyUser.COUNTRY,
				new_user: Number(legacyUser.USER?.NEW_USER),
				offer_id: Number(legacyUser.OFFER_ID),
				sub: 0,
			});
		}
	}, [trackingHook, legacyUser]);

	return trackingHook;
};
