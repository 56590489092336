const path = require('path');
const sprintf = require('i18next-sprintf-postprocessor');
const {availableLanguages} = require('./locales/metas.json');

/** @type { import('next-i18next').UserConfig } */
module.exports = {
	i18n: {
		defaultLocale: 'en',
		locales: availableLanguages,
	},
	compatibilityJSON: 'v3',
	lowerCaseLng: true,
	localePath: path.resolve('./locales'),
	localeStructure: '{{lng}}/{{ns}}',
	nsSeparator: ':::',
	keySeparator: '::',
	postProcess: 'sprintf',
	use: [sprintf],
	serializeConfig: false,
	// Support translation reload in dev
	// https://github.com/i18next/next-i18next#reloading-resources-in-development
	reloadOnPrerender: process.env.NODE_ENV === 'development',
};
