import {GrowthBook} from '@growthbook/growthbook-react';
import {config} from '@modules/config';
import type {
	Attributes,
	Context as GrowthBookContext,
} from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';

const UNIQ_ID_NAME = 'dzr_uniq_id';

export const generateUniqueId = (): string => {
	const randomBytes = new Uint8Array(19);
	crypto.getRandomValues(randomBytes);
	const hexString = Array.prototype.map
		.call(randomBytes, (x) => ('00' + x.toString(16)).slice(-2))
		.join('');
	return `${UNIQ_ID_NAME}_fr${hexString}`;
};

export const getClientSideUniqId = (): string => {
	const cookie = Cookies.get(UNIQ_ID_NAME);
	if (cookie) return cookie;
	const generated = generateUniqueId();
	Cookies.set(UNIQ_ID_NAME, generated, {expires: 90});
	return generated;
};

const trackingCallback: GrowthBookContext['trackingCallback'] = () => {
	// TODO
};

export type GrowthBookAttributes = Attributes & {
	lang?: string;
	geoip: string;
	dzr_uniq_id: string;
	'page-path': string;
	'page-category': string;
	deezer_user_id?: string;
};
export type StaticABTestAttributes = Pick<
	GrowthBookAttributes,
	'page-path' | 'page-category'
>;

export const buildNewGrowthbookInstance = (): GrowthBook =>
	new GrowthBook({
		trackingCallback,
		enableDevMode: config.get('environment') === 'deezerdev',
	});
