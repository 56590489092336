import React from 'react';
import Head from 'next/head';
import type {AppProps} from 'next/app';
import {useRouter} from 'next/router';
import type {SSRConfig} from 'next-i18next';
import {appWithTranslation} from 'next-i18next';
import configNextI18Next from 'next-i18next.config';
import {config} from '@modules/config';
import {DefaultSeo} from 'next-seo';
import faviconIco from '@public/favicon.ico';
import ogDeezerLogo from '@images/common/logo-deezer-og.png';
import AppRoot from '@components/AppRoot';
import {getCurrentUrl} from '@app/modules/location';
import type {LandingProps} from '@app/types/next';
import {getSEOLocaleMetas} from '@app/modules/locale-management';

const App: React.FC<AppProps<SSRConfig & LandingProps>> = ({
	Component,
	pageProps,
}) => {
	const {locale, asPath} = useRouter();
	const languageAlternates = getSEOLocaleMetas(asPath, pageProps?.locales);

	React.useEffect(() => {
		if (document.domain !== 'localhost') {
			// Used for some old browsers to enable some CORS stuff
			document.domain = config.get('cookieDomain').slice(1);
		}
	}, []);

	return (
		<>
			<Head>
				<link rel="icon" type="image/x-icon" href={faviconIco.src} />
			</Head>
			<DefaultSeo
				additionalMetaTags={[
					{
						name: 'viewport',
						content: 'initial-scale=1.0, width=device-width',
					},
				]}
				languageAlternates={languageAlternates}
				canonical={getCurrentUrl({locale, pathname: asPath})}
				openGraph={{
					url: getCurrentUrl({locale, pathname: asPath}),
					type: 'website',
					images: [
						{
							url: ogDeezerLogo.src,
							width: 200,
							height: 200,
							alt: 'Deezer Logo',
						},
					],
				}}
				twitter={{
					site: '@deezer',
					cardType: 'summary',
				}}
			/>
			<AppRoot {...pageProps}>
				<Component {...pageProps} />
			</AppRoot>
		</>
	);
};

export default appWithTranslation(App, configNextI18Next);
