import React from 'react';

import UserProvider from '@deezer/react-user';
import ApiProvider from '@deezer/react-legacy-api';
import ThemeProvider from '@providers/ThemeProvider';
import {SWRConfig} from 'swr';
import {config} from '@modules/config';
import type {LandingProps} from '@app/types/next';
import type {SSRConfig} from 'next-i18next';
import {TrackingProvider} from '@app/modules/tracking';
import {ConsentContextProvider} from '@tempo/cookie';
import dynamic from 'next/dynamic';

const DynamicPerformanceMonitoring = dynamic(() =>
	import('./PerformanceMonitoring').then(
		({PerformanceMonitoring}) => PerformanceMonitoring,
	),
);

const DynamicConsentScreen = dynamic(
	() =>
		import('@app/components/CookieBanner').then(
			({ConsentScreen}) => ConsentScreen,
		) as any,
	{ssr: false},
);

const AppRoot: React.FC<
	{children?: React.ReactNode} & SSRConfig & LandingProps
> = ({children, dehydratedState, tracking}) => {
	return (
		<ApiProvider
			host={config.get('deezerWebsiteUrl').replace('https://', '')}
			mode="cors"
			credentials="include"
		>
			<UserProvider
				hostAccount={config.get('hostAccount')}
				pictureDomain={config.get('deezerImagesUrl').replace('https://', '')}
			>
				<SWRConfig value={{fallback: dehydratedState ?? {}}}>
					<ConsentContextProvider>
						<TrackingProvider tracking={tracking ?? {}}>
							<DynamicPerformanceMonitoring />
							<DynamicConsentScreen />
							<ThemeProvider>{children}</ThemeProvider>
						</TrackingProvider>
					</ConsentContextProvider>
				</SWRConfig>
			</UserProvider>
		</ApiProvider>
	);
};

export default AppRoot;
