import {useLegacyUser} from '@deezer/react-user';
import {usePrevious} from '@tempo/core';
import {useEffect, useState} from 'react';
import {useUserConsent} from '@tempo/cookie';
import {getClientSideUniqId} from '../abTest';
import {CDP} from '@deezer/logcenter';
import {config} from '@modules/config';

type userIdentity = {
	deezer_unique_id?: string;
	customerid?: string;
	email?: string;
	userId?: string;
};

const buildDefaultCDP = () => {
	return new CDP(config.get('segmentApiKey'), {
		logger:
			//eslint-disable-next-line no-console
			config.get('environment') === 'deezerdev' ? console.error : undefined,
	});
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};

/**
 * For init use only, prefer using the one from @app/modules/tracking instead
 */
export const useCDP = () => {
	const [ready, setReady] = useState(false);
	const [legacyUser] = useLegacyUser();
	const previousUserId = usePrevious(legacyUser?.USER?.USER_ID?.toString());
	const previousEmail = usePrevious(legacyUser?.USER?.EMAIL);
	const {consentSettings} = useUserConsent();

	useEffect(() => {
		if (!legacyUser) return;
		const userIdentity: userIdentity = {
			deezer_unique_id: getClientSideUniqId(),
		};
		if (legacyUser?.USER?.EMAIL) {
			userIdentity.email = legacyUser?.USER?.EMAIL;
		}
		if (legacyUser?.USER?.USER_ID) {
			userIdentity.userId = legacyUser?.USER?.USER_ID.toString();
		}
		if (typeof window !== 'undefined' && !ready) {
			getCDP().init();
		}
		// init
		if (!previousUserId && typeof userIdentity.customerid !== 'undefined') {
			getCDP().identify(userIdentity);
			setReady(true);
			return;
		}

		// New user
		if (previousUserId && previousUserId !== userIdentity.customerid) {
			getCDP().logout();
			getCDP().identify(userIdentity);
			setReady(true);
			return;
		}
	}, [previousUserId, previousEmail, legacyUser]);

	useEffect(() => {
		if (ready && consentSettings) {
			try {
				getCDP().setConsent(consentSettings);
			} catch {
				// noop
			}
		}
	}, [ready, consentSettings]);

	return [getCDP(), ready] as const;
};
